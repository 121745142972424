import React from "react"
import LayoutNew from "../../components/LayoutNew"
import { Helmet } from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"

export default function TermsOfUse() {
  return (
    <LayoutNew>
      <Helmet>
        {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
        <title>Moving Made Easy with Purple Cow Internet 💜🐄</title>
      </Helmet>
      <section className="px-4 py-8 mx-auto font-light prose prose-lg">
       
        
        <StaticImage
          layout="constrained"
          src="images/Moving-With-Purple-Cow-Internet.png"
          alt="Purple Cow Internet - Picking the right plan"
          /> 

              <h2>Moving Made Easy with Purple Cow Internet</h2>

              <p>Moving to a new home is an exciting journey filled with fresh starts and new opportunities. At Purple Cow Internet, we understand that amidst the flurry of packing boxes and organizing movers, you’ve got enough on your plate. That’s why we’ve simplified the process of transferring your internet service. Here’s a quick guide on how to take Purple Cow Internet with you to your new home, ensuring a seamless transition.</p>

              <h3>Before the Move: Notify Us Early</h3>

              <p>As soon as you know your moving date, give us a heads up—ideally, a few business days in advance. This advance notice allows us to prepare and ensure everything is set up for a smooth activation at your new residence. Just a quick call or message to our customer service team is all it takes!</p>

              <h3>On Moving Day: Pack and Plug</h3>

              <ol>
                <li><strong>Pack Your Modem:</strong> Before leaving your old home, remember to pack your Purple Cow Internet modem. It’s your key to staying connected, so make sure it’s one of the first things you set aside when packing your tech gadgets.</li>
                <li><strong>Plug In at Your New Home:</strong> Once you’ve moved in, find the coaxial outlet in your new home (it looks just like the one you used before) and plug in your modem. Turn it on and give it about 5 minutes to configure itself. If everything goes smoothly, you should be connected to the internet just like before, without any hassle.</li>
              </ol>

              <h3>Troubleshooting Tips</h3>

              <p>If you plug in your modem and find that it’s not connecting, don’t worry—just try the following steps:</p>

              <ul>
                <li><strong>Check Other Outlets:</strong> Sometimes, not all coaxial outlets are activated. Try plugging the modem into a different outlet to see if that solves the issue.</li>
                <li><strong>Reach Out for Help:</strong> If you’ve tried different outlets and still can’t connect, let us know. We’re here to help and can send a technician to make sure everything is up and running as it should be.</li>
              </ul>

              <h3>Why Move with Purple Cow?</h3>

              <p>Opting to continue your service with Purple Cow Internet at your new location is not only simple but also cost-effective. There’s no need to deal with new contracts, installation appointments, or hidden fees. Our straightforward process eliminates the usual stresses associated with transferring internet services.</p>

              <p><strong>Moving your Purple Cow Internet service is fast, easy, and inexpensive.</strong> We aim to provide continuity and convenience so you can focus on settling into your new home without skipping a beat in connectivity.</p>

              <p>We’re excited to move with you and look forward to providing the same great service at your new address. Welcome home to new beginnings with reliable, hassle-free internet!</p>
                          
            Bradley Farquhar<br></br>
            CEO<br></br>
            <br></br><br></br>

      </section>
    </LayoutNew>
  )
}
